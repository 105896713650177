import { createAction, createAsyncAction } from 'typesafe-actions';
import { GetOwnersResponse, GetOwnersError, Owner } from './types';

export const owners = createAsyncAction(
  'GET_OWNERS_REQUEST',
  'GET_OWNERS_SUCCESS',
  'GET_OWNERS_ERROR',
)<undefined, GetOwnersResponse[], GetOwnersError>();

export const setOwner = createAction('SET_OWNER')<Owner>()
