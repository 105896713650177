import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'utils/types';
import {
  openDeployModal,
  closeDeployModal,
  repos,
  apps,
  envs,
  branches,
  builds,
  deploy,
  cleanDeployState,
  allowed,
} from './actions';
import {
  DeployItemContext,
  DeployResponse,
  GetAppsResponse,
  GetBranchesResponse,
  GetBuildsResponse,
  GetEnvsResponse,
  GetReposResponse,
} from './types';

type State = {
  open: boolean;
  allowed: {
    data: boolean;
    status: RequestStatus;
  };
  modalContext?: DeployItemContext;
  repos: {
    data: GetReposResponse[] | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  apps: {
    data: GetAppsResponse[] | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  envs: {
    data: GetEnvsResponse[] | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  branches: {
    data: GetBranchesResponse[] | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  builds: {
    data: GetBuildsResponse[] | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  deploy: {
    [envName: string]:
      | (DeployResponse & { status: RequestStatus; errorCode: string | null })
      | null;
  };
};

const initialState: State = {
  allowed: {
    data: false,
    status: RequestStatus.INIT,
  },
  apps: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  branches: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  builds: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  deploy: {},
  envs: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  open: false,
  repos: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
};

const sidebarReducer = createReducer(initialState)
  .handleAction(openDeployModal, (state: State, { payload }) => ({
    ...state,
    modalContext: payload.context,
    open: true,
  }))
  .handleAction(closeDeployModal, (state) => ({
    ...initialState,
    allowed: state.allowed,
  }))
  .handleAction(repos.request, (state) => ({
    ...state,
    repos: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(repos.success, (state, action) => ({
    ...state,
    repos: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(repos.failure, (state, action) => ({
    ...state,
    repos: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(apps.request, (state) => ({
    ...state,
    apps: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(apps.success, (state, action) => ({
    ...state,
    apps: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(apps.failure, (state, action) => ({
    ...state,
    apps: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(apps.cancel, (state) => ({
    ...state,
    apps: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.INIT,
    },
  }))
  .handleAction(envs.request, (state) => ({
    ...state,
    envs: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(envs.success, (state, action) => ({
    ...state,
    envs: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(envs.failure, (state, action) => ({
    ...state,
    envs: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(envs.cancel, (state) => ({
    ...state,
    envs: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.INIT,
    },
  }))
  .handleAction(branches.request, (state) => ({
    ...state,
    branches: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(branches.success, (state, action) => ({
    ...state,
    branches: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(branches.failure, (state, action) => ({
    ...state,
    branches: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(branches.cancel, (state) => ({
    ...state,
    branches: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.INIT,
    },
  }))
  .handleAction(builds.request, (state) => ({
    ...state,
    builds: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(builds.success, (state, action) => ({
    ...state,
    builds: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(builds.failure, (state, action) => ({
    ...state,
    builds: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(builds.cancel, (state) => ({
    ...state,
    builds: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.INIT,
    },
  }))
  .handleAction(
    deploy.request,
    (
      state,
      {
        payload: {
          payload: { env, stage },
        },
      },
    ) => ({
      ...state,
      deploy: {
        ...state.deploy,
        [stage ? `${env} - ${stage}` : env]: {
          errorCode: null,
          message: 'Starting deployment...',
          status: RequestStatus.PENDING,
        },
      },
    }),
  )
  .handleAction(
    deploy.success,
    (state, { payload: { env, stage, message } }) => ({
      ...state,
      deploy: {
        ...state.deploy,
        [stage ? `${env} - ${stage}` : env]: {
          errorCode: null,
          message,
          status: RequestStatus.SUCCESS,
        },
      },
    }),
  )
  .handleAction(
    deploy.failure,
    (state, { payload: { env, stage, message, code } }) => ({
      ...state,
      deploy: {
        ...state.deploy,
        [stage ? `${env} - ${stage}` : env]: {
          errorCode: code,
          message,
          status: RequestStatus.FAILED,
        },
      },
    }),
  )
  .handleAction(cleanDeployState, (state) => ({
    ...state,
    deploy: {},
  }))
  .handleAction(allowed.request, (state) => ({
    ...state,
    allowed: {
      data: false,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(allowed.success, (state) => ({
    ...state,
    allowed: {
      data: true,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(allowed.failure, (state) => ({
    ...state,
    allowed: {
      data: false,
      status: RequestStatus.FAILED,
    },
  }));

export default sidebarReducer;
