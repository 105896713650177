import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

const request = async (params: AxiosRequestConfig) => {
  const session = await Auth.currentSession();

  if (!params.headers) {
    params.headers = {};
  }

  params.headers['Authorization'] = `Bearer ${session
    .getIdToken()
    .getJwtToken()}`;

  return axios({
    ...params,
  });
};

export const get = (props: AxiosRequestConfig, baseURL?: string) =>
  request({
    baseURL: baseURL || process.env.REACT_APP_BASE_URL,
    ...props,
    method: 'GET',
  });

export const post = (props: AxiosRequestConfig, baseURL?: string) =>
  request({
    baseURL: baseURL || process.env.REACT_APP_BASE_URL,
    ...props,
    method: 'POST',
  });

export const put = (props: AxiosRequestConfig, baseURL?: string) =>
  request({
    baseURL: baseURL || process.env.REACT_APP_BASE_URL,
    ...props,
    method: 'PUT',
  });

export const patch = (props: AxiosRequestConfig, baseURL?: string) =>
  request({
    baseURL: baseURL || process.env.REACT_APP_BASE_URL,
    ...props,
    method: 'PATCH',
  });

export const del = (props: AxiosRequestConfig, baseURL?: string) =>
  request({
    baseURL: baseURL || process.env.REACT_APP_BASE_URL,
    ...props,
    method: 'DELETE',
  });

export type ResponseError = {
  code: string;
  message: string;
};

export function handleAxiosError(err: unknown): ResponseError {
  if (err instanceof AxiosError && err.response) {
    return {
      code: getStatusText(err),
      message: err.response.data.message,
    };
  }

  return {
    code: 'Unknown Error',
    message: (err as Error).message,
  };
}

function getStatusText(err: AxiosError<unknown, unknown>): string {
  switch (err.response?.status) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Not Found';
    case 500:
      return 'Internal Server Error';
    default:
      return 'Unknown Error';
  }
}

export default {
  del,
  get,
  patch,
  post,
  put,
};
