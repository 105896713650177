import { ResponseError } from 'api';
import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  DeployItemContext,
  DeployRequest,
  DeployResponse,
  GetAppsRequest,
  GetAppsResponse,
  GetBranchesRequest,
  GetBranchesResponse,
  GetBuildsRequest,
  GetBuildsResponse,
  GetEnvsRequest,
  GetEnvsResponse,
  GetReposRequest,
  GetReposResponse,
} from './types';

export const openDeployModal = createAction('OPEN_DEPLOY_MODAL')<{
  context?: DeployItemContext;
}>();
export const closeDeployModal = createAction('CLOSE_DEPLOY_MODAL')<undefined>();

export const repos = createAsyncAction(
  'GET_DEPLOY_REPOS_REQUEST',
  'GET_DEPLOY_REPOS_SUCCESS',
  'GET_DEPLOY_REPOS_ERROR',
)<GetReposRequest, GetReposResponse[], ResponseError>();

export const apps = createAsyncAction(
  'GET_DEPLOY_APPS_REQUEST',
  'GET_DEPLOY_APPS_SUCCESS',
  'GET_DEPLOY_APPS_ERROR',
  'GET_DEPLOY_APPS_CANCEL',
)<GetAppsRequest, GetAppsResponse[], ResponseError, void>();

export const envs = createAsyncAction(
  'GET_DEPLOY_ENVS_REQUEST',
  'GET_DEPLOY_ENVS_SUCCESS',
  'GET_DEPLOY_ENVS_ERROR',
  'GET_DEPLOY_ENVS_CANCEL',
)<GetEnvsRequest, GetEnvsResponse[], ResponseError, void>();

export const branches = createAsyncAction(
  'GET_DEPLOY_BRANCHES_REQUEST',
  'GET_DEPLOY_BRANCHES_SUCCESS',
  'GET_DEPLOY_BRANCHES_ERROR',
  'GET_DEPLOY_BRANCHES_CANCEL',
)<GetBranchesRequest, GetBranchesResponse[], ResponseError, void>();

export const builds = createAsyncAction(
  'GET_DEPLOY_BUILDS_REQUEST',
  'GET_DEPLOY_BUILDS_SUCCESS',
  'GET_DEPLOY_BUILDS_ERROR',
  'GET_DEPLOY_BUILDS_CANCEL',
)<GetBuildsRequest, GetBuildsResponse[], ResponseError, void>();

export const cleanDeployState = createAction('CLEAN_DEPLOY_STATE')<undefined>();

export const deploy = createAsyncAction(
  'DEPLOY_REQUEST',
  'DEPLOY_SUCCESS',
  'DEPLOY_ERROR',
)<
  DeployRequest,
  DeployResponse & { env: string, stage?: string },
  ResponseError & { env: string, stage?: string }
>();

export const allowed = createAsyncAction(
  'CHECK_DEPLOY_PERMISSIONS_REQUEST',
  'CHECK_DEPLOY_PERMISSIONS_SUCCESS',
  'CHECK_DEPLOY_PERMISSIONS_ERROR',
)<void, void, ResponseError>();
