import { get } from 'api';
import { CancelToken } from 'axios';

type QueryStringType = string | null | undefined;

export const getDashboardInfo = (
  owner: string,
  project?: QueryStringType,
  repo?: QueryStringType,
  cancelToken?: CancelToken,
) =>
  get({
    cancelToken,
    params: { project, repo },
    url: `/${owner}/dashboard`,
  });
