import { handleAxiosError } from 'api';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  repos,
  apps,
  envs,
  branches,
  builds,
  deploy,
  allowed,
} from './actions';
import {
  getReposList,
  getAppsList,
  getEnvsList,
  getBranchesList,
  getBuildsList,
  postDeploy,
  checkDeployPermissions,
} from './service';
import {
  GetReposRequest,
  GetReposResponse,
  GetAppsRequest,
  GetAppsResponse,
  GetEnvsRequest,
  GetEnvsResponse,
  GetBranchesRequest,
  GetBranchesResponse,
  GetBuildsRequest,
  GetBuildsResponse,
  DeployRequest,
  DeployResponse,
} from './types';

function* reposSaga({ payload }: { payload: GetReposRequest }): Generator {
  try {
    const response = (yield call(getReposList, payload.owner)) as {
      data: GetReposResponse[];
    };
    yield put(repos.success(response.data));
  } catch (err) {
    yield put(repos.failure(handleAxiosError(err)));
  }
}

function* appsSaga({ payload }: { payload: GetAppsRequest }): Generator {
  try {
    const response = (yield call(
      getAppsList,
      payload.owner,
      payload.repository,
    )) as { data: GetAppsResponse[] };
    yield put(apps.success(response.data));
  } catch (err) {
    yield put(apps.failure(handleAxiosError(err)));
  }
}

function* envsSaga({ payload }: { payload: GetEnvsRequest }): Generator {
  try {
    const response = (yield call(
      getEnvsList,
      payload.owner,
      payload.repository,
      payload.app,
    )) as { data: GetEnvsResponse[] };
    yield put(envs.success(response.data));
  } catch (err) {
    yield put(envs.failure(handleAxiosError(err)));
  }
}

function* branchesSaga({
  payload,
}: {
  payload: GetBranchesRequest;
}): Generator {
  try {
    const response = (yield call(
      getBranchesList,
      payload.owner,
      payload.repository,
      payload.app,
    )) as { data: GetBranchesResponse[] };
    yield put(branches.success(response.data));
  } catch (err: unknown) {
    yield put(branches.failure(handleAxiosError(err)));
  }
}

function* buildsSaga({ payload }: { payload: GetBuildsRequest }): Generator {
  try {
    const response = (yield call(
      getBuildsList,
      payload.owner,
      payload.repository,
      payload.branch,
      payload.app,
    )) as { data: GetBuildsResponse[] };
    yield put(builds.success(response.data));
  } catch (err) {
    yield put(builds.failure(handleAxiosError(err)));
  }
}

function* deploySaga({ payload }: { payload: DeployRequest }): Generator {
  try {
    const response = (yield call(
      postDeploy,
      payload.owner,
      payload.payload,
    )) as { data: DeployResponse };
    yield put(deploy.success({ ...response.data, env: payload.payload.env, stage: payload.payload.stage }));
  } catch (err) {
    yield put(
      deploy.failure({ ...handleAxiosError(err), env: payload.payload.env, stage: payload.payload.stage }),
    );
  }
}

function* checkDeployPermissionsSaga(): Generator {
  try {
    yield call(checkDeployPermissions);
    yield put(allowed.success());
  } catch (err) {
    yield put(allowed.failure(handleAxiosError(err)));
  }
}

export default [
  takeEvery(repos.request, reposSaga),
  takeEvery(apps.request, appsSaga),
  takeEvery(envs.request, envsSaga),
  takeEvery(branches.request, branchesSaga),
  takeEvery(builds.request, buildsSaga),
  takeEvery(deploy.request, deploySaga),
  takeEvery(allowed.request, checkDeployPermissionsSaga),
];
