import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createMiddlewares, { sagaMiddleware } from './middlewares';
import RootSaga from './rootSaga';
import rootReducer from './rootReducer';

const persistedConfig = {
  key: 'root',
  storage,
  whitelist: ['sidebar'],
};

const persistedReducer = persistReducer(persistedConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...createMiddlewares())),
);

sagaMiddleware.run(RootSaga);

export const persistor = persistStore(store);
