import Axios from 'axios';
import { takeEvery, call, put, cancelled } from 'redux-saga/effects';
import { dashboard } from './actions';
import { getDashboardInfo } from './service';
import { DashboardError, DashboardResponse, GetDashboardRequest } from './types';

type Response = {
  data: DashboardResponse[];
};

function* dashboardSaga({ payload }: { payload: GetDashboardRequest }): Generator {
  try {
    const clientData: Response = (yield call(
      getDashboardInfo,
      payload.owner,
      payload.project,
      payload.repo,
      payload.cancelTokenSource?.token
    )) as Response;
    yield put(dashboard.success(clientData.data.filter((item) => item.cd.length > 0)));
  } catch (err) {
    if (err instanceof Axios.Cancel) {
      yield put(dashboard.success([]));
    } else {
      yield put(dashboard.failure(err as DashboardError));
    }
  } finally {
    if (yield cancelled()) {
      if (payload.cancelTokenSource) {
        yield call(payload.cancelTokenSource.cancel)
      }
    }
  }
}

export default [takeEvery(dashboard.request, dashboardSaga)];
