import { createAsyncAction } from 'typesafe-actions';
import {
  GetMetricViewsRequest,
  GetMetricViewsResponse,
  AddMetricViewRequest,
  UpdateMetricViewRequest,
  MetricView,
  DeleteMetricViewRequest,
} from './types';
import { ResponseError } from 'api';

export const views = createAsyncAction(
  'GET_METRIC_VIEWS_REQUEST',
  'GET_METRIC_VIEWS_SUCCESS',
  'GET_METRIC_VIEWS_ERROR',
)<GetMetricViewsRequest, GetMetricViewsResponse, ResponseError>();

export const addView = createAsyncAction(
  'ADD_METRIC_VIEW_REQUEST',
  'ADD_METRIC_VIEW_SUCCESS',
  'ADD_METRIC_VIEW_ERROR',
)<AddMetricViewRequest, MetricView, ResponseError>();

export const updateView = createAsyncAction(
  'UPDATE_METRIC_VIEW_REQUEST',
  'UPDATE_METRIC_VIEW_SUCCESS',
  'UPDATE_METRIC_VIEW_ERROR',
)<UpdateMetricViewRequest, MetricView, ResponseError>();

export const deleteView = createAsyncAction(
  'DELETE_METRIC_VIEW_REQUEST',
  'DELETE_METRIC_VIEW_SUCCESS',
  'DELETE_METRIC_VIEW_ERROR',
)<DeleteMetricViewRequest, string, ResponseError>();
