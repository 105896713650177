import React, { FC, lazy, ReactNode, Suspense, useMemo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import { GlobalStyle } from 'theme/global';
import { Authenticator } from '@aws-amplify/ui-react';
import { QueryParamProvider } from 'use-query-params';
import InitMachine from 'components/InitMachine';
import {
  BrowserRouter,
  useNavigate,
  useLocation,
  Location,
} from 'react-router-dom';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#3f51b5',
    },
  },
});

const MainContainer = lazy(() => import('./pages/Main/Container'));
const Modals = lazy(() => import('./pages/Main/Modal'));

const RouteV6Adapter: FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adaptedHistory = useMemo(
    () => ({
      push: ({ search, state }: Location) => navigate({ search }, { state }),
      replace: ({ search, state }: Location) =>
        navigate({ search }, { replace: true, state }),
    }),
    [navigate],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Authenticator.Provider>
              <InitMachine>
                <BrowserRouter>
                  <QueryParamProvider ReactRouterRoute={RouteV6Adapter}>
                    <GlobalStyle />
                    <Suspense fallback={<div>Loading...</div>}>
                      <MainContainer />
                      <Modals />
                    </Suspense>
                  </QueryParamProvider>
                </BrowserRouter>
              </InitMachine>
            </Authenticator.Provider>
          </PersistGate>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </>
);

export default App;
