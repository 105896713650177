import { combineReducers } from 'redux';
import { History, createBrowserHistory } from 'history';
import sidebarReducer from 'modules/sidebar/reducers';
import dashboardReducer from 'modules/dashboard/reducers';
import buildReducer from 'modules/build/reducers';
import buildLogsReducer from 'modules/build-logs/reducers';
import projectsReducer from 'modules/projects/reducers';
import reposReducer from 'modules/repos/reducers';
import ownersReducer from 'modules/owners/reducers';
import deployReducer from 'modules/deploy/reducers';
import metricsReducer from 'modules/metrics/reducers';
import appsReducer from 'modules/apps/reducers';
import metricViewsReducer from 'modules/metric-views/reducers';

export const history: History = createBrowserHistory();

const rootReducer = combineReducers({
  apps: appsReducer,
  build: buildReducer,
  buildLogs: buildLogsReducer,
  dashboard: dashboardReducer,
  deploy: deployReducer,
  metricViews: metricViewsReducer,
  metrics: metricsReducer,
  owners: ownersReducer,
  projects: projectsReducer,
  repos: reposReducer,
  sidebar: sidebarReducer
});

export default rootReducer;
