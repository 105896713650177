import { takeEvery, call, put } from 'redux-saga/effects';
import { buildLogs } from './actions';
import { getBuildLogs } from './service';
import { BuildLogsResponse, BuildLogsRequest, BuildError } from './types';

type SagaBuildLogsResponse = {
  data: BuildLogsResponse;
};

function* buildLogsSaga({ payload }: { payload: BuildLogsRequest }): Generator {
  try {
    const response: SagaBuildLogsResponse = (yield call(
      getBuildLogs,
      payload.repository,
      payload.timestamp,
      payload.owner
    )) as SagaBuildLogsResponse;
    yield put(buildLogs.success(response.data));
  } catch (err) {
    yield put(buildLogs.failure(err as BuildError));
  }
}

export default [takeEvery(buildLogs.request, buildLogsSaga)];
