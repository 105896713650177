import { takeEvery, call, put } from 'redux-saga/effects';
import { projects } from './actions';
import { getProjectsList } from './service';
import { GetProjectsError, GetProjectsResponse, GetProjectsRequest } from './types';

type Response = {
  data: GetProjectsResponse[];
};

function* projectsSaga({ payload }: { payload: GetProjectsRequest }): Generator {
  try {
    const respData: Response = (yield call(getProjectsList, payload.owner)) as Response;
    yield put(projects.success(respData.data));
  } catch (err) {
    yield put(projects.failure(err as GetProjectsError));
  }
}

export default [takeEvery(projects.request, projectsSaga)];
