import { createReducer } from 'typesafe-actions';
import { apps } from './actions';
import { App } from './types';
import { RequestStatus } from 'utils/types';

type State = {
  data: App[] | null;
  message: string | null;
  status: RequestStatus | null;
};

const initialState: State = {
  data: null,
  message: null,
  status: null,
};

const appsReducer = createReducer(initialState)
  .handleAction(apps.request, (state) => ({
    ...state,
    message: null,
    status: RequestStatus.PENDING,
  }))
  .handleAction(apps.success, (state, action) => ({
    ...state,
    data: [
      ...action.payload,
    ],
    message: null,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(apps.failure, (state, action) => ({
    ...state,
    data: null,
    message: action.payload.message,
    status: RequestStatus.FAILED,
  }))

export default appsReducer;
