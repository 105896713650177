import { takeEvery, call, put } from 'redux-saga/effects';
import { repos } from './actions';
import { getReposList } from './service';
import { GetReposError, GetReposRequest, GetReposResponse } from './types';

type Response = {
  data: GetReposResponse[];
};

function* reposSaga({ payload }: { payload: GetReposRequest }): Generator {
  try {
    const respData: Response = (yield call(
      getReposList,
      payload.owner,
      payload.project
    )) as Response;
    yield put(repos.success(respData.data));
  } catch (err) {
    yield put(repos.failure(err as GetReposError));
  }
}

export default [takeEvery(repos.request, reposSaga)];
