import { get } from 'api';

export const getBuildList = (
  repository: string,
  environment: string,
  owner: string,
  source?: string | null,
  app?: string | null,
) => {
  const url = `${owner}/detail/${repository}/${environment}`;

  return get({
    params: { app, source },
    url
  });
};
