import React, { useRef, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AuthenticatorMachineOptions } from '@aws-amplify/ui';

function InitMachine({
  children,
  ...data
}: AuthenticatorMachineOptions & { children: React.ReactNode }) {
  const { _send, route } = useAuthenticator(({ route }) => [route]);
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current && route === 'idle') {
      _send({
        data,
        type: 'INIT',
      });
      hasInitialized.current = true;
    }
  }, [_send, route, data]);
  return <>{children}</>;
}

export default InitMachine;
