import { takeEvery, call, put } from 'redux-saga/effects';
import { views, addView, updateView, deleteView } from './actions';
import { getViewsList, postView, putView, delView } from './service';
import {
  GetMetricViewsRequest,
  GetMetricViewsResponse,
  AddMetricViewRequest,
  UpdateMetricViewRequest,
  DeleteMetricViewRequest,
  MetricView,
} from './types';
import { handleAxiosError } from 'api';

type CollectionResponse = {
  data: GetMetricViewsResponse;
};

type MetricViewResponse = {
  data: MetricView;
};

function* viewsSaga({
  payload,
}: {
  payload: GetMetricViewsRequest;
}): Generator {
  try {
    const respData: CollectionResponse = (yield call(
      getViewsList,
      payload.owner,
    )) as CollectionResponse;
    yield put(views.success(respData.data));
  } catch (err) {
    yield put(views.failure(handleAxiosError(err)));
  }
}

function* addViewSaga({
  payload,
}: {
  payload: AddMetricViewRequest;
}): Generator {
  try {
    const respData: MetricViewResponse = (yield call(
      postView,
      payload,
    )) as MetricViewResponse;
    yield put(addView.success(respData.data));
  } catch (err) {
    yield put(addView.failure(handleAxiosError(err)));
  }
}

function* updateViewSaga({
  payload,
}: {
  payload: UpdateMetricViewRequest;
}): Generator {
  try {
    const respData: MetricViewResponse = (yield call(
      putView,
      payload,
    )) as MetricViewResponse;
    yield put(updateView.success(respData.data));
  } catch (err) {
    yield put(updateView.failure(handleAxiosError(err)));
  }
}

function* deleteViewSaga({
  payload,
}: {
  payload: DeleteMetricViewRequest;
}): Generator {
  try {
    yield call(delView, payload.owner, payload.viewId);
    yield put(deleteView.success(payload.viewId));
  } catch (err) {
    yield put(deleteView.failure(handleAxiosError(err)));
  }
}

export default [
  takeEvery(views.request, viewsSaga),
  takeEvery(addView.request, addViewSaga),
  takeEvery(updateView.request, updateViewSaga),
  takeEvery(deleteView.request, deleteViewSaga),
];
