import { handleAxiosError } from 'api';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { apps } from './actions';
import { getAppsList } from './service';
import { GetAppsRequest, GetAppsResponse } from './types';

type Response = {
  data: GetAppsResponse;
};

function* appsSaga({ payload }: { payload: GetAppsRequest }): Generator {
  try {
    const tasks = (yield all(
      payload.repos.map((repo) => call(getAppsList, payload.owner, repo)),
    )) as Response[];

    const response = tasks.reduce((acc, task) => {
      acc.data.push(...task.data);
      return acc;
    }, { data: [] } as Response);

    yield put(apps.success(response.data));
  } catch (err) {
    yield put(apps.failure(handleAxiosError(err)));
  }
}

export default [takeEvery(apps.request, appsSaga)];
