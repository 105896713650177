import { handleAxiosError } from 'api';
import { takeEvery, call, put } from 'redux-saga/effects';
import { deploymentPerEnv, builds, deploys, deploysByTime } from './actions';
import { getMetrics } from './service';
import {
  GetDeploymentPerEnvRequest,
  GetDeploymentPerEnvResponse,
  GetMetricsResponse,
  GetMetricsRequest,
  MetricRequest,
  Granularity,
  Metric,
  GetBuildsRequest,
  GetDeploysRequest,
  GetDeployByTimeRequest,
  GetDeploysByTimeResponse,
  BaseMetricRequest,
} from './types';
import _ from 'lodash';

function* deploymentPerEnvSaga({
  payload,
}: {
  payload: GetDeploymentPerEnvRequest;
}): Generator {
  function getMetricRequest(status: string, env: string): MetricRequest {
    const metricRange = {
      end: `${payload.dateRange.endDate.toISOString().split('T')[0]} 23:00`,
      granularity: 'none' as Granularity,
      start: `${payload.dateRange.startDate.toISOString().split('T')[0]} 00:00`,
    };

    return {
      metricRange,
      ...getNamespaces(payload, `deploy#${status}#${payload.owner}#${env}`),
    }
  }

  try {
    const metrics: MetricRequest[] = ['success', 'failed']
      .map((status) => payload.envs.map((env) => getMetricRequest(status, env)))
      .flat(1);

    const requestBody: GetMetricsRequest = {
      metrics,
    };

    const response = (yield call(getMetrics, requestBody)) as {
      data: GetMetricsResponse;
    };

    const metricsResponse = payload.envs.map((env, index) => ({
      failed: (response.data[index + payload.envs.length] as Metric).value,
      name: env.toUpperCase(),
      success: (response.data[index] as Metric).value,
    }));

    yield put(
      deploymentPerEnv.success(metricsResponse as GetDeploymentPerEnvResponse),
    );
  } catch (err) {
    yield put(deploymentPerEnv.failure(handleAxiosError(err)));
  }
}

function* buildsSaga({ payload }: { payload: GetBuildsRequest }): Generator {
  try {
    const metricRange = {
      end: `${payload.dateRange.endDate.toISOString().split('T')[0]} 23:00`,
      granularity: 'none' as Granularity,
      start: `${payload.dateRange.startDate.toISOString().split('T')[0]} 00:00`,
    };

    const requestBody: GetMetricsRequest = {
      metrics: [
        {
          ...getNamespaces(payload, `build#success#${payload.owner}`),
          metricRange: metricRange,
        },
        {
          ...getNamespaces(payload, `build#failed#${payload.owner}`),
          metricRange: metricRange,
        },
      ],
    };

    const response = (yield call(getMetrics, requestBody)) as {
      data: GetMetricsResponse;
    };

    const [buildSuccess, buildFailed] = response.data;

    yield put(
      builds.success([
        {
          name: 'Build (success)',
          value: (buildSuccess as Metric).value,
        },
        {
          name: 'Build (failed)',
          value: (buildFailed as Metric).value,
        },
      ]),
    );
  } catch (err) {
    yield put(builds.failure(handleAxiosError(err)));
  }
}

function* deploysSaga({ payload }: { payload: GetDeploysRequest }): Generator {
  try {
    const metricRange = {
      end: `${payload.dateRange.endDate.toISOString().split('T')[0]} 23:00`,
      granularity: 'none' as Granularity,
      start: `${payload.dateRange.startDate.toISOString().split('T')[0]} 00:00`,
    };

    const requestBody: GetMetricsRequest = {
      metrics: [
        {
          ...getNamespaces(payload, `deploy#success#${payload.owner}`),
          metricRange: metricRange,
        },
        {
          ...getNamespaces(payload, `deploy#failed#${payload.owner}`),
          metricRange: metricRange,
        },
      ],
    };

    const response = (yield call(getMetrics, requestBody)) as {
      data: GetMetricsResponse;
    };

    const [deploySuccess, deployFailed] = response.data;

    yield put(
      deploys.success([
        {
          name: 'Deploy (success)',
          value: (deploySuccess as Metric).value,
        },
        {
          name: 'Deploy (failed)',
          value: (deployFailed as Metric).value,
        },
      ]),
    );
  } catch (err) {
    yield put(deploys.failure(handleAxiosError(err)));
  }
}

function* deploysByTimeSaga({
  payload,
}: {
  payload: GetDeployByTimeRequest;
}): Generator {
  try {
    const endDate = payload.dateRange.endDate.toISOString().split('T')[0];
    const startDate = payload.dateRange.startDate.toISOString().split('T')[0];

    const metricRange = {
      end: `${endDate} 23:00`,
      granularity: (startDate === endDate ? 'hour' : 'day') as Granularity,
      start: `${startDate} 00:00`,
    };

    const metrics: MetricRequest[] = ['success', 'failed']
      .map((status) =>
        payload.envs.map((env) => ({
          ...getNamespaces(payload, `deploy#${status}#${
            payload.owner
          }#${env}`),
          metricRange: metricRange,
        })),
      )
      .flat(1);

    const requestBody: GetMetricsRequest = {
      metrics,
    };

    const response = (yield call(getMetrics, requestBody)) as {
      data: GetMetricsResponse;
    };

    const [firstMetric] = response.data;

    const metricTimes = (firstMetric as Metric[]).map((metric: Metric) => ({
      name: metric.metricTime,
      ...payload.envs.reduce((prev, curr, index) => {
        return {
          ...prev,
          [curr]:
            ((response.data[index] as Metric[]).find(
              (m) => m.metricTime === metric.metricTime,
            )?.value || 0) +
            ((response.data[index + payload.envs.length] as Metric[]).find(
              (m) => m.metricTime === metric.metricTime,
            )?.value || 0),
        };
      }, {} as { [env: string]: unknown }),
    }));

    yield put(deploysByTime.success(metricTimes as GetDeploysByTimeResponse));
  } catch (err) {
    yield put(deploysByTime.failure(handleAxiosError(err)));
  }
}

function getNamespaces(
  payload: BaseMetricRequest,
  baseNamespace: string,
): { metricNamespace: string } | { metricNamespaces: string[] } {
  const repos = payload.apps.map(({ hash }) => hash.split('#')[1]);
  const namespaces = [
    ...payload.repos.filter(repo => !repos.includes(repo)),
    ...payload.apps.map(({ app, hash }) => `${hash.split('#')[1]}#${app}`),
  ]

  if (namespaces.length === 0) {
    return {
      metricNamespace: `${baseNamespace}${getProjectNamespace(payload)}`,
    };
  } else {
    return {
      metricNamespaces: namespaces.map((namespace) => `${baseNamespace}#${namespace}`),
    };
  }
}

function getProjectNamespace(payload: BaseMetricRequest): string {
  if (!_.isNil(payload.project) && !_.isEmpty(payload.project)) {
    return `#${payload.project}`;
  }

  return '';
}

export default [
  takeEvery(deploymentPerEnv.request, deploymentPerEnvSaga),
  takeEvery(builds.request, buildsSaga),
  takeEvery(deploys.request, deploysSaga),
  takeEvery(deploysByTime.request, deploysByTimeSaga),
];
