import { createReducer } from 'typesafe-actions';
import { openSidebar, closeSidebar } from './actions';

type State = {
  open: boolean;
};

const initialState: State = {
  open: false,
};

const sidebarReducer = createReducer(initialState)
  .handleAction(openSidebar, (state: State) => ({
    ...state,
    open: true,
  }))
  .handleAction(closeSidebar, (state: State) => ({
    ...state,
    open: false,
  }));

export default sidebarReducer;
