import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'utils/types';
import { deploymentPerEnv, builds, deploys, deploysByTime } from './actions';
import {
  GetDeploymentPerEnvResponse,
  GetBuildsResponse,
  GetDeploysResponse,
  GetDeploysByTimeResponse
} from './types';

type State = {
  deploymentPerEnv: {
    data: GetDeploymentPerEnvResponse | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  deploymentByTime: {
    data: GetDeploysByTimeResponse | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  builds: {
    data: GetBuildsResponse | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
  deploys: {
    data: GetDeploysResponse | null;
    message: string | null;
    errorCode: string | null;
    status: RequestStatus;
  };
};

const initialState: State = {
  builds: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  deploymentByTime: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  deploymentPerEnv: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
  deploys: {
    data: null,
    errorCode: null,
    message: null,
    status: RequestStatus.INIT,
  },
};

const metricsReducer = createReducer(initialState)
  .handleAction(deploymentPerEnv.request, (state) => ({
    ...state,
    deploymentPerEnv: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(deploymentPerEnv.success, (state, action) => ({
    ...state,
    deploymentPerEnv: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(deploymentPerEnv.failure, (state, action) => ({
    ...state,
    deploymentPerEnv: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(builds.request, (state) => ({
    ...state,
    builds: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(builds.success, (state, action) => ({
    ...state,
    builds: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(builds.failure, (state, action) => ({
    ...state,
    builds: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(deploys.request, (state) => ({
    ...state,
    deploys: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(deploys.success, (state, action) => ({
    ...state,
    deploys: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(deploys.failure, (state, action) => ({
    ...state,
    deploys: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(deploysByTime.request, (state) => ({
    ...state,
    deploymentByTime: {
      data: null,
      errorCode: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(deploysByTime.success, (state, action) => ({
    ...state,
    deploymentByTime: {
      data: [...action.payload],
      errorCode: null,
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(deploysByTime.failure, (state, action) => ({
    ...state,
    deploymentByTime: {
      data: null,
      errorCode: action.payload.code,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }));

export default metricsReducer;
