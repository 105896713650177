import { takeEvery, call, put } from 'redux-saga/effects';
import { buildList } from './actions';
import { getBuildList } from './service';
import { BuildError, BuildItemResponse, BuildListRequest } from './types';

type SagaBuildListResponse = {
  data: BuildItemResponse[];
};

function* buildListSaga({ payload }: { payload: BuildListRequest }): Generator {
  try {
    const response: SagaBuildListResponse = (yield call(
      getBuildList,
      payload.repository,
      payload.environment,
      payload.owner,
      payload.source,
      payload.app
    )) as SagaBuildListResponse;
    yield put(buildList.success(response.data));
  } catch (err) {
    yield put(buildList.failure(err as BuildError));
  }
}

export default [takeEvery(buildList.request, buildListSaga)];
