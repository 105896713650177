import { createReducer } from 'typesafe-actions';
import { views, addView, updateView, deleteView } from './actions';
import { GetMetricViewsResponse, MetricView } from './types';
import { RequestStatus } from 'utils/types';

type State = {
  views: {
    data: GetMetricViewsResponse | null;
    message: string | null;
    status: RequestStatus;
  };
  addView: {
    data: MetricView | null;
    message: string | null;
    status: RequestStatus;
  };
  updateView: {
    data: MetricView | null;
    message: string | null;
    status: RequestStatus;
  };
  deleteView: {
    message: string | null;
    status: RequestStatus;
  }
};

const initialState: State = {
  addView: {
    data: null,
    message: null,
    status: RequestStatus.INIT,
  },
  deleteView: {
    message: null,
    status: RequestStatus.INIT,
  },
  updateView: {
    data: null,
    message: null,
    status: RequestStatus.INIT,
  },
  views: {
    data: null,
    message: null,
    status: RequestStatus.INIT,
  },
};

const viewsReducer = createReducer(initialState)
  .handleAction(views.request, (state) => ({
    ...state,
    views: {
      data: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(views.success, (state, action) => ({
    ...state,
    views: {
      data: [...action.payload],
      message: null,
      status: RequestStatus.SUCCESS,
    },
  }))
  .handleAction(views.failure, (state, action) => ({
    ...state,
    views: {
      data: null,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(addView.request, (state) => ({
    ...state,
    addView: {
      data: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(addView.success, (state, action) => ({
    ...state,
    addView: {
      data: {
        ...action.payload,
      },
      message: null,
      status: RequestStatus.SUCCESS,
    },
    views: {
      ...state.views,
      data: [...(state.views.data ?? []), action.payload],
    },
  }))
  .handleAction(addView.failure, (state, action) => ({
    ...state,
    addView: {
      data: null,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(updateView.request, (state) => ({
    ...state,
    updateView: {
      data: null,
      message: null,
      status: RequestStatus.PENDING,
    },
  }))
  .handleAction(updateView.success, (state, action) => ({
    ...state,
    updateView: {
      data: {
        ...action.payload,
      },
      message: null,
      status: RequestStatus.SUCCESS,
    },
    views: {
      ...state.views,
      data: state.views.data?.map((view) => {
        if (view.id === action.payload.id) {
          return action.payload;
        }
        return view;
      }) ?? [action.payload],
    },
  }))
  .handleAction(updateView.failure, (state, action) => ({
    ...state,
    updateView: {
      data: null,
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }))
  .handleAction(deleteView.request, (state) => ({
    ...state,
    deleteView: {
      message: null,
      status: RequestStatus.PENDING,
    }
  }))
  .handleAction(deleteView.success, (state, action) => ({
    ...state,
    deleteView: {
      message: null,
      status: RequestStatus.SUCCESS,
    },
    views: {
      ...state.views,
      data: state.views.data?.filter(view => view.id !== action.payload) ?? [],
    },
  }))
  .handleAction(deleteView.failure, (state, action) => ({
    ...state,
    deleteView: {
      message: action.payload.message,
      status: RequestStatus.FAILED,
    },
  }));

export default viewsReducer;
