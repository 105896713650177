import { all } from 'redux-saga/effects';
import dashboardSaga from 'modules/dashboard/sagas';
import buildSaga from 'modules/build/sagas';
import buildLogsSaga from 'modules/build-logs/sagas';
import projectsSaga from 'modules/projects/sagas';
import reposSaga from 'modules/repos/sagas';
import ownersSaga from 'modules/owners/sagas';
import deploySaga from 'modules/deploy/sagas';
import metricsSaga from 'modules/metrics/sagas';
import appsSaga from 'modules/apps/sagas';
import metricViewsSaga from 'modules/metric-views/sagas';

export default function* rootSaga() {
  yield all([
    ...appsSaga,
    ...dashboardSaga,
    ...buildSaga,
    ...buildLogsSaga,
    ...projectsSaga,
    ...reposSaga,
    ...ownersSaga,
    ...deploySaga,
    ...metricsSaga,
    ...metricViewsSaga,
  ]);
}
