import { get, post } from 'api';
import { DeployPayloadRequest } from './types';

export const getReposList = (owner: string) =>
  get({
    url: `/${owner}/deploy/repos`,
  });

export const getAppsList = (owner: string, repository: string) =>
  get({
    url: `/${owner}/deploy/${repository}/apps`,
  });

export const getEnvsList = (
  owner: string,
  repository: string,
  app?: string | null,
) =>
  get({
    params: app ? { app } : {},
    url: `/${owner}/deploy/${repository}/envs`,
  });

export const getBranchesList = (
  owner: string,
  repository: string,
  app?: string | null,
) =>
  get({
    params: app ? { app } : {},
    url: `/${owner}/deploy/${repository}/branches`,
  });

export const getBuildsList = (
  owner: string,
  repository: string,
  branch: string,
  app?: string | null,
) =>
  get({
    params: app ? { app } : {},
    url: `/${owner}/deploy/${repository}/${encodeURIComponent(branch)}/builds`,
  });

export const postDeploy = (owner: string, payload: DeployPayloadRequest) =>
  post({
    data: payload,
    url: `/${owner}/deploy`,
  });

export const checkDeployPermissions = () =>
  post({
    data: {
      action: 'deploy:Deploy',
    },
    url: `/check-permissions`,
  });
