import { ResponseError } from 'api';
import { createAsyncAction } from 'typesafe-actions';
import {
  GetBuildsRequest,
  GetBuildsResponse,
  GetDeploysRequest,
  GetDeploysResponse,
  GetDeploymentPerEnvRequest,
  GetDeploymentPerEnvResponse,
  GetDeployByTimeRequest,
  GetDeploysByTimeResponse,
} from './types';

export const deploymentPerEnv = createAsyncAction(
  'GET_DEPLOYMENT_PER_ENV_METRICS_REQUEST',
  'GET_DEPLOYMENT_PER_ENV_METRICS_SUCCESS',
  'GET_DEPLOYMENT_PER_ENV_METRICS_ERROR',
)<GetDeploymentPerEnvRequest, GetDeploymentPerEnvResponse, ResponseError>();

export const builds = createAsyncAction(
  'GET_BUILDS_METRICS_REQUEST',
  'GET_BUILDS_METRICS_SUCCESS',
  'GET_BUILDS_METRICS_ERROR',
)<GetBuildsRequest, GetBuildsResponse, ResponseError>();

export const deploys = createAsyncAction(
  'GET_DEPLOYS_METRICS_REQUEST',
  'GET_DEPLOYS_METRICS_SUCCESS',
  'GET_DEPLOYS_METRICS_ERROR',
)<GetDeploysRequest, GetDeploysResponse, ResponseError>();

export const deploysByTime = createAsyncAction(
  'GET_DEPLOYS_BY_TIME_METRICS_REQUEST',
  'GET_DEPLOYS_BY_TIME_METRICS_SUCCESS',
  'GET_DEPLOYS_BY_TIME_METRICS_ERROR',
)<GetDeployByTimeRequest, GetDeploysByTimeResponse, ResponseError>();
