import { takeEvery, call, put } from 'redux-saga/effects';
import { owners } from './actions';
import { getOwnersList } from './service';
import { GetOwnersError, GetOwnersResponse } from './types';

type Response = {
  data: GetOwnersResponse[];
};

function* ownersSaga(): Generator {
  try {
    const respData: Response = (yield call(getOwnersList)) as Response;
    yield put(owners.success(respData.data));
  } catch (err) {
    yield put(owners.failure(err as GetOwnersError));
  }
}

export default [takeEvery(owners.request, ownersSaga)];
