import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
});

Auth.configure({
  oauth: {
    domain: process.env.REACT_APP_USER_POOL_DOMAIN,
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'token',
    scope: ['email', 'profile', 'openid']
  },
});
