import { get, post, put, del } from 'api';
import { AddMetricViewRequest, UpdateMetricViewRequest } from './types';

export const getViewsList = (owner: string) =>
  get({
    url: `/${owner}/metrics/views`,
  });

export const postView = (payload: AddMetricViewRequest) =>
  post({
    data: payload,
    url: `/${payload.owner}/metrics/view`,
  });

export const putView = (payload: UpdateMetricViewRequest) =>
  put({
    data: payload,
    url: `/${payload.owner}/metrics/view/${payload.id}`,
  });

export const delView = (owner: string, viewId: string) =>
  del({
    url: `/${owner}/metrics/view/${viewId}`,
  });
