import { createReducer } from 'typesafe-actions';
import { buildList } from './actions';
import { BuildItemResponse } from './types';
import { RequestStatus } from 'utils/types';

type State = {
  data: BuildItemResponse[] | null;
  message: string | null;
  status: RequestStatus | null;
};

const initialState: State = {
  data: null,
  message: null,
  status: null,
};

const buildReducer = createReducer(initialState)
  .handleAction(buildList.request, (state) => ({
    ...state,
    data: null,
    message: null,
    status: RequestStatus.PENDING,
  }))
  .handleAction(buildList.success, (state, action) => ({
    ...state,
    data: [...action.payload],
    message: null,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(buildList.failure, (state, action) => ({
    ...state,
    data: null,
    message: action.payload.message,
    status: RequestStatus.FAILED,
  }));

export default buildReducer;
